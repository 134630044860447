import React from "react"
import Typography from "@mui/material/Typography"
import StoresList from "../../components/StoresList/StoresList.tsx"
import { useGetStores } from "../../hooks/firestore/useGetStores.ts"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"

const MyPopstore = () => {
  const user = useFirebaseUser()
  const { stores, isLoading } = useGetStores(user?.user?.uid)

  if (!stores | isLoading) return null
  const storesInTransfer = stores.filter((s) => s.inTransfer)
  const storesNotInTransfer = stores.filter((s) => !s.inTransfer)

  return (
    <>
      <Typography gutterBottom variant="h4">
        My stores
      </Typography>

      <StoresList showNoContent stores={storesNotInTransfer} />
      {storesInTransfer.length > 0 && (
        <StoresList header="Stores in transfer" stores={storesInTransfer} />
      )}
    </>
  )
}

export default MyPopstore
