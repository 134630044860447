import { useEffect, useState } from "react"
import { IStore } from "../../types/IStore"
import { db, doc, collection, getDoc } from "../../service/firebase"

export const useGetStore = (ownerId, storeId) => {
  const [store, setStore] = useState<IStore | null>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string | undefined>()

  const hasError = !!errorText

  useEffect(() => {
    setIsLoading(true)
    const storesRef = collection(db, `/StoreOwners/${ownerId}/allStores`)
    getDoc(doc(storesRef, storeId)).then((r) => {
      const s = r.data()
      if (s) {
        setStore(s as IStore)
        setIsLoading(false)
      } else {
        setErrorText("No store found")
        setIsLoading(false)
      }
    })
  }, [storeId, ownerId])
  return { store, isLoading, hasError, errorText }
}
