import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"

import { useNavigate } from "react-router-dom"
import firebase, { signInWithGoogle } from "../../service/firebase"
import DataTable from "../../components/DataTable/DataTable"
import textToCellsParser from "../../functions/textToCellsParser"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import StepGuide from "./StepGuide.tsx"

const LandingPage = () => {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const [sheetData, setSheetData] = useState([])
  const [pastedData, setPastedData] = useState("")

  const MySwal = withReactContent(Swal)

  const handlePaste = (e) => {
    const data = e.clipboardData.getData("text/plain")
    setPastedData(data)
    const cells = textToCellsParser(data)
    setSheetData(cells)
  }

  const saveSheet = async (e) => {
    const validation = sheetData.every(
      (item) => Array.isArray(item.cells) && item.cells.length,
    )
    if (!validation) {
      await MySwal.fire({
        title: "Error!",
        text: "Please paste correct sheet data to proceed",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }
    localStorage.setItem("sheetData", JSON.stringify(sheetData))
    e.preventDefault()
    await CheckLogin()
  }

  const CheckLogin = async () => {
    const user = firebase.auth().currentUser
    if (user) {
      navigate("/popstore/create")
    } else {
      await signInWithGoogle()
    }
  }

  const clearSheet = () => {
    setSheetData([])
    setPastedData("")
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (localStorage.getItem("sheetData") !== null) {
          navigate("/popstore/create")
        } else {
          navigate("/")
        }
      }
    })
  }, [navigate])

  return (
    <>
      <Typography gutterBottom variant="h4">
        Create a PopStore in 2 min!
      </Typography>
      <form onSubmit={saveSheet}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Copy a range from a spreadsheet & paste here"
          helperText=""
          variant="outlined"
          onPaste={handlePaste}
          value={pastedData}
          multiline
          minRows={5}
        />
        <Typography sx={{ mt: 0.5 }} gutterBottom variant="subtitle2">
          First time? See a tutorial{" "}
          <Link component="a" href="https://popstore.blog/onboarding/">
            here
          </Link>
          .
        </Typography>
        <Stack direction="row" gap={2} mt={2}>
          <Button
            color="primary"
            variant="contained"
            disabled={!sheetData.length}
            onClick={saveSheet}
          >
            Go
          </Button>
          <Button
            color="secondary"
            variant="contained"
            disabled={typeof sheetData === "undefined" || !sheetData.length}
            onClick={clearSheet}
          >
            Clear
          </Button>
        </Stack>
      </form>

      {sheetData.length > 0 ? (
        <Box
          sx={{
            backgroundColor: palette.grey[200],
            width: "100%",
            minHeight: "300px",
            marginTop: "20px",
            borderRadius: "10px",
          }}
        >
          <DataTable sheet={sheetData} />
        </Box>
      ) : (
        <StepGuide />
      )}
    </>
  )
}

export default LandingPage
