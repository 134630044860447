import { flatten, map, sumBy, groupBy } from "lodash"
export const mergeAndSumItems = (arr: any[]) => {
  const filteredArray = arr.map((items) =>
    items.filter((item) => item.quantity !== 0 && item.quantity !== null),
  )

  const groupedById = groupBy(flatten(filteredArray), "id")

  const result = map(groupedById, (items) => {
    const { id, name, price } = items[0]
    const quantity = sumBy(items, "quantity")

    return {
      id,
      name,
      quantity,
      price,
    }
  })

  return result
}
