import {
  AppBar,
  Avatar,
  Button,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material"
import React from "react"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"
import AppMenu from "../AppMenu/AppMenu.js"
import LoginButton from "../LoginButton/LoginButton.js"
import useDeviceSize from "../../hooks/useDeviceSize.ts"
import firebase from "../../service/firebase.js"
import { useNavigate } from "react-router-dom"

const Logo = () => (
  <Typography
    variant="h5"
    noWrap
    component="a"
    href="https://popstore.blog/"
    sx={{
      mr: 3,
      fontFamily: "monospace",
      fontWeight: 700,
      letterSpacing: ".3rem",
      color: "inherit",
      textDecoration: "none",
    }}
  >
    POPSTORE
  </Typography>
)

const Header = () => {
  const { user, isAdmin } = useFirebaseUser()
  const { mediumScreenDown } = useDeviceSize()
  const navigate = useNavigate()
  const handleLogout = () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear()
        window.location.assign("/")
      })
  const settings = [
    {
      label: "Create store",
      onClick: () => navigate("/"),
    },
    {
      label: "My stores",
      onClick: () => navigate("/popstore/all"),
    },
  ]

  if (isAdmin)
    settings.push({
      label: "Admin",
      onClick: () => navigate("/admin"),
    })
  if (!mediumScreenDown)
    return (
      <AppBar
        sx={{ boxShadow: "none", mt: 1 }}
        color="transparent"
        position="static"
      >
        <Toolbar disableGutters>
          <Logo />
          {!mediumScreenDown && (
            <Stack sx={{ flexGrow: 1 }} direction="row">
              {user && (
                <>
                  {settings.map((s) => (
                    <Button key={s.label} size="large" onClick={s.onClick}>
                      {s.label}
                    </Button>
                  ))}
                </>
              )}
            </Stack>
          )}
          {user ? (
            <Stack sx={{ mt: 1 }} alignItems="center">
              <Avatar
                sx={{ width: 50, height: 50 }}
                alt={user?.displayName as string | undefined}
                src={user?.photoURL as string | undefined}
              />
              <Button onClick={handleLogout}>Logout</Button>
            </Stack>
          ) : (
            <LoginButton />
          )}
          {mediumScreenDown && <AppMenu settings={settings} />}
        </Toolbar>
      </AppBar>
    )
  return (
    <AppBar
      sx={{ boxShadow: "none", mt: 1, mb:2 }}
      color="transparent"
      position="static"
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Logo />
        <AppMenu settings={settings} />
      </Toolbar>
    </AppBar>
  )
}

export default Header
