import { getDocs, collectionGroup } from "firebase/firestore"
import { db } from "./firebase"

const collection_name = "allStores"

export const findAll = async () => {
  const doc_refs = await getDocs(collectionGroup(db, collection_name))

  const res = []
  doc_refs.forEach((store) => {
    res.push({id:store.id, ...store.data()})
  })
  return res
}
