import { Button, Link, Modal, Paper, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { signInWithGoogle } from "../../service/firebase"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"
import { Google } from "@mui/icons-material"


const LoginButton = () => {
  const [open, setOpen] = useState(false)
  const { loggedIn, isLoading } = useFirebaseUser()
  const handleLogin = () => {
    signInWithGoogle()
  }
  if (loggedIn || isLoading) return null
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-login"
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { md: 600, sm: "100%" },
            height: "fit-content",
            p: 3,
            overflow: "scroll",
          }}
        >
          <Stack>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Welcome to popstore
            </Typography>
            <Button
              onClick={handleLogin}
              startIcon={<Google/>}
              sx={{ mt: 2, width: "fit-content" }}
              variant="outlined"
              size="large"
            >
              Continue with Google
            </Button>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              By continuing, you agree to PopStore’s{" "}
              <Link
                component="a"
                target="_blank"
                href="https://popstore.blog/terms-and-conditions/"
              >
                Terms & Conditions
              </Link>
              .
            </Typography>
           
          </Stack>
        </Paper>
      </Modal>
      <Button size="large" onClick={() => setOpen(true)}>
        Login
      </Button>
    </>
  )
}

export default LoginButton