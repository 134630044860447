import { Box, Card, CardContent, Stack, Typography } from "@mui/material"
import React from "react"
import useDeviceSize from "../../hooks/useDeviceSize.ts"
import documentImage from "./document.png"
import describeImage from "./describe.png"
import shareImage from "./share.png"

const StepGuideItem = ({ header, image, body }) => (
  <Card
    elevation={0}
    sx={{ p: 2, backgroundColor: "#FFCA90", maxWidth: "250px", my: 1 }}
  >
    <CardContent>
      <Stack gap={2}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>{image}</Box>
        <Typography variant="subtitle1">{header}</Typography>
        <Typography variant="body2">{body}</Typography>
      </Stack>
    </CardContent>
  </Card>
)

const StepGuide = () => {
  const { mediumScreensUp } = useDeviceSize()
  return (
    <Box
      sx={{
        my: 8,
        py: 5,
        backgroundColor: "#D9F4EE",
        boxShadow: "0 0 0 100vmax #D9F4EE",
        clipPath: "inset(0 -100vmax)",
      }}
    >
      <Typography sx={{ mb: 5 }} variant="h5">
        3 steps to a PopStore
      </Typography>
      <Stack
        alignItems={mediumScreensUp ? "unset" : "center"}
        justifyContent={"space-between"}
        direction={mediumScreensUp ? "row" : "column"}
      >
        <StepGuideItem
          header="1 - COPY A LIST FROM A SPREADSHEET"
          body={
            "Use a list that you already have, and copy. It’s probably in the right format already!"
          }
          image={<img height="200px" alt="input" src={documentImage} />}
        />
        <StepGuideItem
          header="2 - PASTE INTO POPSTORE & DESCRIBE"
          body={
            "In PopSto.re, paste and describe (login with a Google account). You will create a web order form with its own url."
          }
          image={<img height="200px" alt="describe" src={describeImage} />}
        />
        <StepGuideItem
          header="3 - SHARE WEB ORDER FORM"
          body={
            "Share the url to your web order form with your network. See the orders in the dashboard."
          }
          image={<img height="200px" alt="share" src={shareImage} />}
        />
      </Stack>
    </Box>
  )
}

export default StepGuide
