import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import React, { useState } from "react"
import { IStore } from "../../types/IStore"
import { deleteStore } from "./deleteStore.ts"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../service/firebase.js"

interface IDeleteStoreButtonProps {
  store: IStore
}

const DeleteStoreButton: React.FC<IDeleteStoreButtonProps> = ({ store }) => {
  const [open, setOpen] = useState(false)
  const [deleted, setDeleted] = useState(false)

  const handleDeleteClick = () => {
    deleteStore(store.storeID, store.ownerID)
      .then((r) => {
        logEvent(analytics, "store_deleted", {
          name: store.storeName,
        })
        setDeleted(true)
        setTimeout(() => {
          setOpen(false)
          setDeleted(false)
        }, 2000)
      })
      .catch((e) => console.log(e))
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="delete-store-title"
        aria-describedby="delete-store-description"
      >
        <DialogTitle id="delete-store-title">Delete store</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-store-description">
            By deleting the store you remove all information about the store,
            orders and customers. It's not possible to restore any information.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!deleted && <Button onClick={() => setOpen(false)}>Cancel</Button>}
          {deleted ? (
            <Button
              color="error"
              disabled
              onClick={handleDeleteClick}
              autoFocus
            >
              Deleting
            </Button>
          ) : (
            <Button color="error" onClick={handleDeleteClick} autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Button disabled={deleted} color="error" onClick={() => setOpen(true)}>
        Delete
      </Button>
    </>
  )
}

export default DeleteStoreButton
