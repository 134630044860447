import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { findAll } from "../../service/storeOwners"
import {
  filterStoresByLast24h,
  filterStoresByLast30days,
  filterStoresByLast7days,
  filterStoresByLastYear,
} from "../../functions/filterStoresByCreated"
import useDeviceSize from "../../hooks/useDeviceSize.ts"
import { LockOutlined } from "@mui/icons-material"

const AdminPage = () => {
  const [stores, setStores] = useState([])
  const fetchData = async () => {
    const res = await findAll()

    res.sort((a, b) => b.createAt - a.createAt)
    setStores([...res])
  }

  useEffect(() => {
    fetchData()
  }, [])

  const storesLast24h = filterStoresByLast24h(stores)
  const storesLast7Days = filterStoresByLast7days(stores)
  const storesLast30Days = filterStoresByLast30days(stores)
  const storesLastYear = filterStoresByLastYear(stores)

  const { mediumScreensUp } = useDeviceSize()
  return (
    <>
      <Typography variant="h4">Admin</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Box>
          {stores.map((s) => (
            <Stack
              key={`${s.storeID}-${s.ownerID}`}
              sx={{ mt: 2 }}
              justifyContent="space-between"
            >
              <Link
                component={RouterLink}
                to={`/store/${s.ownerID}/${s.storeID}`}
              >
                <Typography>{s.storeName}</Typography>
              </Link>
              <Typography>
                {s.storeCreator ? s.storeCreator : s.storeOwner}
              </Typography>
              <Stack direction="row" gap={1}>
                <Typography variant="body1">
                  Created:{" "}
                  {new Date(s?.createAt?.seconds * 1000).toDateString()}
                </Typography>
                {s.locked && (
                  <Tooltip title="Store is locked">
                    <LockOutlined color="primary" />
                  </Tooltip>
                )}
              </Stack>
              {s.inTransfer && (
                <Alert severity="info">
                  <Typography>
                    This store is being transfered to {s.storeOwner}
                  </Typography>
                </Alert>
              )}
              <Stack sx={{ mb: 3, mt: 1 }} direction="row" gap={1}>
                <Button
                  component={RouterLink}
                  to={`/store/${s.ownerID}/${s.storeID}/orders`}
                  variant="outlined"
                >
                  Orders
                </Button>
                <Button
                  component={RouterLink}
                  to={`/store/${s.ownerID}/${s.storeID}/customers`}
                  variant="outlined"
                >
                  Customers
                </Button>
              </Stack>
            </Stack>
          ))}
        </Box>
        {mediumScreensUp && (
          <Card sx={{ height: "fit-content" }} variant="outlined">
            <CardContent>
              <Typography variant="subtitle1">
                {`${storesLast24h.length} new ${
                  storesLast24h.length > 1 ? "popstores" : "popstore"
                } last 24 hours `}
              </Typography>
              <Typography variant="subtitle1">
                {`${storesLast7Days.length} new ${
                  storesLast7Days.length > 1 ? "popstores" : "popstore"
                } last 7 days `}
              </Typography>
              <Typography variant="subtitle1">
                {`${storesLast30Days.length} new ${
                  storesLast30Days.length > 1 ? "popstores" : "popstore"
                } last 30 days `}
              </Typography>
              <Typography variant="subtitle1">
                {`${storesLastYear.length} new ${
                  storesLastYear.length > 1 ? "popstores" : "popstore"
                } last year `}
              </Typography>
              <Typography variant="subtitle1">
                Total {stores.length} popstores
              </Typography>
            </CardContent>
          </Card>
        )}
      </Stack>
    </>
  )
}

export default AdminPage
