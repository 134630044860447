import { IOrderItem } from "../../../types/IOrderItem.ts"
import { mergeAndSumItems } from "./mergeAndSumItems.ts"

export const stackCustomerItems = (orders: any[]) => {
  const items = [] as IOrderItem[]
  orders.forEach((o) => {
    items.push(o.order)
  })
  return mergeAndSumItems(items)
}
