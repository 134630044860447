import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { getUserID } from "../../service/getUserId.ts"
import { validateEmail } from "../../functions/validateEmail.js"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"

interface IFirebaseUser {
  id: string
  name: string
  email: string
  photoUrl: string
}
interface IAddAdminProps {
  setStoreOwner: (user: string | null) => void
}

const AddOwner: React.FC<IAddAdminProps> = ({ setStoreOwner }) => {
  const [checked, setChecked] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [error, setError] = useState<string | null>("")
  const [user, setUser] = useState<IFirebaseUser | null>()
  const [newUser, setNewUser] = useState<string | null>()
  const { user: firebaseUser } = useFirebaseUser()

  const handleAddAdminClick = () => {
    if (validateEmail(email)) {
      setError("")
      if (firebaseUser?.email === email) setError("You can't add yourself")
      else
        getUserID(email)
          .then((r) => {
            setUser(r.data as IFirebaseUser)
            const usr = r.data as IFirebaseUser
            setStoreOwner(usr.email)
          })
          .catch((e) => {
            console.log(e)
            setNewUser(email)
            setStoreOwner(email)
          })
    } else {
      setError("Invalid email")
    }
  }

  useEffect(() => {
    if (!checked) {
      setNewUser(null)
      setUser(null)
      setStoreOwner(null)
    }
  }, [checked, setStoreOwner])

  return (
    <Stack gap={2} sx={{ my: 2 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={() => setChecked(!checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Select another user as owner of this store"
        />
      </FormGroup>
      {checked && (
        <Box>
          <Typography variant="body2">
            An e-mail will be sent to confirm ownership for this store.
          </Typography>
          {!user && !newUser && (
            <Stack direction="row">
              <TextField
                value={email}
                onChange={(v) => setEmail(v.target.value)}
                sx={{ maxWidth: "400px", my: 2 }}
                type={"email"}
                label="Email"
                variant="outlined"
                helperText={error}
                error={error !== ""}
              />
              <Button onClick={handleAddAdminClick}>Add</Button>
            </Stack>
          )}
          {newUser && (
            <Card
              sx={{
                width: "fit-content",
                my: 2,
              }}
            >
              <CardContent>
                <Typography>{newUser}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    setUser(null)
                    setNewUser(null)
                    setStoreOwner(null)
                  }}
                  size="small"
                  color="primary"
                >
                  Remove
                </Button>
              </CardActions>
            </Card>
          )}
          {user && (
            <Card
              sx={{
                width: "fit-content",
                my: 2,
              }}
            >
              <CardContent>
                <Avatar
                  alt={user.name}
                  src={user.photoUrl}
                  sx={{ width: 56, height: 56, margin: "auto", my: 1 }}
                />
                <Typography>{user.name}</Typography>
                <Typography>{user.email}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => {
                    setUser(null)
                    setStoreOwner(null)
                  }}
                  size="small"
                  color="primary"
                >
                  Remove
                </Button>
              </CardActions>
            </Card>
          )}
        </Box>
      )}
    </Stack>
  )
}

export default AddOwner
