import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"
import firebase from "../../service/firebase"
import LoginButton from "../LoginButton/LoginButton.js"

const AppMenu = ({ settings }) => {
  const { user } = useFirebaseUser()

  const [anchorElUser, setAnchorElUser] = useState(null)
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }
  const handleLogout = () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear()
        window.location.assign("/")
      })
  if (!user) return <LoginButton/>
  return (
    <Stack>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            sx={{ width: 56, height: 56 }}
            alt={user.displayName}
            src={user.photoURL}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings?.map((setting) => (
          <MenuItem key={setting?.label} onClick={handleCloseUserMenu}>
            <Typography onClick={setting?.onClick} textAlign="center">
              {setting?.label}
            </Typography>
          </MenuItem>
        ))}
        <MenuItem onClick={handleCloseUserMenu}>
          <Typography onClick={()=>handleLogout()} textAlign="center">
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </Stack>
  )
}

export default AppMenu