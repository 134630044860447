import { useEffect, useState } from "react"
import firebase from "../service/firebase"
import { User as FirebaseUser } from "firebase/auth"

export const useFirebaseUser = () => {
  const [user, setUser] = useState<FirebaseUser | null>()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleUser = (user) => {
    if (user) {
      setUser(user)
      user
        .getIdTokenResult()
        .then((idTokenResult) => setIsAdmin(idTokenResult.claims.admin))
    } else {
      setUser(null)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(handleUser)
    return () => unsubscribe()
  }, [])

  return { user, isLoading, isAdmin }
}
