import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import firebase, {
  doc,
  getDoc,
  db,
  collection,
  getDocs,
} from "../../service/firebase"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, MenuItem, Select } from "@mui/material"
import BackButton from "../../components/BackButton/BackButton.tsx"

const PackingPage = () => {
  const navigate = useNavigate()
  const { storeId } = useParams()
  const [hasUnconfirmedOrders, setHasUnconfirmedOrders] = useState(false)
  const [store, setStore] = useState({})
  const [product, setProduct] = useState({})
  const [customers, setCustomers] = useState([])
  const [orders, setOrders] = useState([])
  const [customerProducts, setCustomerProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const storesRef = await collection(
          db,
          `/StoreOwners/${user.uid}/allStores`,
        )
        const store = await getDoc(doc(storesRef, storeId))
        if (store.exists()) {
          let data = store.data()

          data.columnsList = JSON.parse(data.columnsList)
          setStore(data)
        }
        const customersRef = collection(
          db,
          `/StoreOwners/${user.uid}/allStores/${storeId}/customers`,
        )
        const Customers = await getDocs(customersRef)
        let tempCustomers = []
        Customers.forEach((doc) => {
          tempCustomers.push(doc.data())
        })
        const ordersRef = collection(
          db,
          `/StoreOwners/${user.uid}/allStores/${storeId}/Orders`,
        )
        const querySnapshot = await getDocs(ordersRef)
        let tempOrders = []
        querySnapshot.forEach((doc, i) => {
          let d = doc.data()
          if (d.confirmed === false) setHasUnconfirmedOrders(true)
          if (d.confirmed === false) return
          tempOrders.push(d)
        })
        let orders = []
        tempOrders.forEach((o) => {
          JSON.parse(o.order).forEach((p) => {
            if (p !== null) {
              // check if order already exists
              let index = orders.findIndex((e) => e.id === p.id)
              if (index === -1) {
                orders.push(p)
              } else {
                orders[index].quantity += p.quantity
              }
            }
          })
        })
        setOrders(orders)

        // Loop through customers and make an array of customers along with their product ids
        let tempCustomerProducts = []
        tempCustomers.forEach((c) => {
          let customer = {
            email: c.email,
            name: c.name,
            products: [],
          }
          tempOrders.forEach((o) => {
            if (o.email === c.email) {
              JSON.parse(o.order).forEach((p) => {
                if (p !== null) {
                  // check if product already exists and update quantity
                  let index = customer.products.findIndex((e) => e.id === p.id)
                  if (index === -1) {
                    customer.products.push({ id: p.id, quantity: p.quantity })
                  } else {
                    customer.products[index].quantity += p.quantity
                  }
                }
              })
            }
          })
          tempCustomerProducts.push(customer)
        })
        setCustomerProducts(tempCustomerProducts)
        setIsLoading(false)
      } else {
        navigate("/")
      }
    })
  }, [navigate, storeId])

  const getProductOrders = (p) => {
    // get all customers information from customerProducts based on the id of product
    let temp = []
    customerProducts.forEach((c) => {
      let index = c.products.findIndex((e) => e.id === p.id)
      if (index !== -1) {
        temp.push({
          user: c.email,
          name: c.name,
          quantity: c.products[index].quantity,
        })
      }
    })
    setCustomers(temp)
    setProduct(p)
    document.getElementById("product").textContent =
      store.columnsList[p.id][1] + " Ordered " + p.quantity
  }
  if (isLoading) return null
  if (orders.length < 1)
    return (
      <>
        <BackButton />
        <Typography gutterBottom variant="h4">
          Packing List
        </Typography>
        <Typography gutterBottom variant="h6">
          No orders yet
        </Typography>
      </>
    )
  else
    return (
      <>
        <div className="popstore-wrapper">
          <BackButton />
          {hasUnconfirmedOrders && (
            <Alert severity="info">
              <Typography>
                This store has unconfirmed orders that is not shown in this
                list.
              </Typography>
            </Alert>
          )}
          <Grid className="pop-header-wrapper" container spacing={2}>
            <Grid item xs={4} md={4}>
              <Typography style={{ marginBottom: "20px" }} variant="h4">
                Packing List
              </Typography>
            </Grid>
          </Grid>
          <Grid className="pop-header-wrapper" container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography style={{ marginBottom: "1rem" }} variant="h6">
                {store?.storeName}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={8} md={5}>
              <h5>Product</h5>
            </Grid>
            <Grid item xs={4} md={3}>
              <h5>Quantity</h5>
            </Grid>
          </Grid>
          {orders?.map((order, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={8} md={5}>
                  <p>{store.columnsList[order.id][1]}</p>
                </Grid>
                <Grid item xs={4} md={3}>
                  <p>{order.quantity}</p>
                </Grid>
              </Grid>
            )
          })}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={5} md={5}>
            <div style={{ padding: "1rem 0" }}>
              <Select fullWidth={true} id="product" label="Select Column">
                {orders?.map((order, i) => (
                  <MenuItem
                    onClick={(e) => getProductOrders(order)}
                    key={`${i}`}
                  >
                    {store.columnsList[order.id][1]} - {order.quantity} Ordered
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        </Grid>
        <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
          {product.id !== undefined && (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={8} md={5}>
                  <h5>Orders: {store.columnsList[product.id][1]}</h5>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8} md={3}>
                  <h5>Customer</h5>
                </Grid>
                <Grid item xs={4} md={3}>
                  <h5>Quantity</h5>
                </Grid>
              </Grid>
              {customers?.map((customer, i) => (
                <Grid container spacing={2} key={i}>
                  <Grid item xs={8} md={3}>
                    <p>{customer.user}</p>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <p>{customer.quantity}</p>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
        </div>
        <p>&nbsp;</p>
      </>
    )
}

export default PackingPage
