import React, { useEffect, useState } from "react"
import { IStore } from "../../types/IStore"
import { Box, Button, Link, Stack, Tooltip, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { ContentCopy, LockOutlined } from "@mui/icons-material"
import DeleteStoreButton from "../DeleteStoreButton/DeleteStoreButton.tsx"

interface IStoreItemProps {
  s: IStore
}

const StoreItem: React.FC<IStoreItemProps> = ({ s }) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link)
    setCopySuccess(true)
  }

  useEffect(() => {
    setTimeout(() => setCopySuccess(false), 1500)
  }, [copySuccess])
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Stack direction="row" gap={1}>
        <Link
          variant="h6"
          component={RouterLink}
          to={`/store/${s.ownerID}/${s.storeID}`}
        >
          {s.storeName}
        </Link>
      
      </Stack>
      <Stack direction="row" gap={1}>
        <Typography variant="body1">
          Created: {new Date(s?.createAt?.seconds * 1000).toDateString()}
        </Typography>
        {s.locked && (
          <Tooltip title="Store is locked">
            <LockOutlined color="primary" />
          </Tooltip>
        )}
      </Stack>
      <Stack direction={"row"} sx={{ my: 1 }} gap={2}>
        <Link to={`/popstore/edit/${s.storeID}`} component={RouterLink}>
          Edit
        </Link>
        <Link
          to={`/store/${s.ownerID}/${s.storeID}/orders`}
          component={RouterLink}
        >
          Orders
        </Link>
        <Link
          to={`/store/${s.ownerID}/${s.storeID}/customers`}
          component={RouterLink}
        >
          Customers
        </Link>
        <Link to={`/popstore/packaging/${s.storeID}/`} component={RouterLink}>
          Packing
        </Link>
      </Stack>
      <Stack sx={{ mt: 1 }} direction={"row"} gap={2}>
        <Button
          sx={{ minWidth: "150px" }}
          disabled={copySuccess}
          variant="outlined"
          onClick={() => copyToClipboard(s.link)}
          startIcon={<ContentCopy />}
        >
          {!copySuccess ? "Copy link" : "Copied!"}
        </Button>
        <DeleteStoreButton store={s} />
      </Stack>
    </Box>
  )
}

export default StoreItem
