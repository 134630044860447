import { FormControlLabel, Switch, Typography } from "@mui/material"
import React from "react"

interface ILockStoreSwitchProps {
  locked: boolean
  onChange: (boolean) => void
}

const LockStoreSwitch: React.FC<ILockStoreSwitchProps> = ({
  locked,
  onChange,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={locked}
            onChange={(e) => onChange(e.target.checked)}
          />
        }
        label="Lock store"
      />
      <Typography variant="body2" sx={{ mb: 3 }}>
        Locking a store prevents customers from placing orders. The store can be opened again at any time.
      </Typography>
    </>
  )
}

export default LockStoreSwitch
