import sendMail from "../../service/email"
import { IStore } from "../../types/IStore"

export const sendTransferEmail = (
  email: string,
  store: IStore,
  userId: string,
) => {
  let orderConfirmationEmail = `
                  <!doctype html>
                  <html lang="en">
                  <head>
                  <style>
                     body{
                          font-family: 'Arial', Helvetica, Arial, Lucida, sans-serif;
                     }
                  </style>
                  <title>New popstore</title>
                  </head>
                  <body>
                  <h1>A new popstore has been created for you!</h1>
                  <p>${store.storeCreator} has created a popstore for you. Accept taking over the store by following this <a href="https://popsto.re/popstore/${store.storeID}/transfer/${userId}">link</a></p>
                  <p></p>
                  <p>&nbsp;</p>
                  <p>Regards</p>
                  <p>PopStore Team</p>
                  </body>
                  </html>
                  `
  sendMail(email, "PopStore Transfer request", orderConfirmationEmail)
}
