import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import firebase, {
  db,
  doc,
  setDoc,
  collection,
  serverTimestamp,
  analytics,
} from "../../service/firebase"
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import textToPriceParser from "../../functions/textToPriceParser"
import AddOwner from "./AddOwner.tsx"
import { sendTransferEmail } from "./sendTransferEmail.ts"
import LockStoreSwitch from "../../components/LockStoreSwitch/LockStoreSwitch.tsx"
import { logEvent } from "firebase/analytics"
import {
  getCurrencyInfoByLocale,
  uniqueCurrencies,
} from "./currencies.ts"

const NewPopstore = () => {
  const { palette } = useTheme()
  const [storeOwner, setStoreOwner] = useState()
  const navigate = useNavigate()
  const [user, setUser] = useState()
  const [storeName, setStoreName] = useState("")
  const [storeCreator, setStoreCreator] = useState("")
  const [storeDescription, setStoreDescription] = useState("")
  const [locked, setLocked] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(
    getCurrencyInfoByLocale(navigator?.languages?.[0]),
  )
  const [sheetData, setSheetData] = useState([])
  const [columns, setColumns] = useState([])
  const [dbColumns] = useState([
    "Select Column",
    "Name",
    "Reference ID",
    "Price",
    "Ignore",
  ])
  const [col, setCol] = useState({})

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user)
        setStoreCreator(user.email)
      } else {
        navigate("/")
      }
      if (localStorage.getItem("sheetData") !== null) {
        let data = JSON.parse(localStorage.getItem("sheetData"))
        setSheetData(data)
        setColumns(data[0].cells)
      } else {
        navigate("/")
      }
      localStorage.setItem(
        "columns",
        JSON.stringify({
          Name: -1,
          "Reference ID": -1,
          Price: -1,
          Ignore: 9,
        }),
      )
      setCol({
        Name: -1,
        "Reference ID": -1,
        Price: -1,
        Ignore: 9,
      })
    })
  }, [navigate])

  const saveStore = async (e) => {
    e.preventDefault()
    let columns = JSON.parse(localStorage.getItem("columns"))
    let referenceIdColumn = columns["Reference ID"]
    let priceColumn = columns["Price"]
    let nameColumn = columns["Name"]
    let descriptionColumn = columns["Description"]

    if (nameColumn === -1) {
      await MySwal.fire({
        title: "Error!",
        text: "Please select a column for Name of products",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    if (priceColumn === -1) {
      await MySwal.fire({
        title: "Error!",
        text: "Please select a column for Price of products",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    if (storeName.trim() === "") {
      await MySwal.fire({
        title: "Error!",
        text: "Please select a name for PopStore",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    if (storeCreator.trim() === "") {
      await MySwal.fire({
        title: "Error!",
        text: "Please add an email for PopStore owner",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    if (storeDescription.trim() === "") {
      await MySwal.fire({
        title: "Error!",
        text: "Please add description for PopStore",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    let productsPrices = []

    let products = []

    if (referenceIdColumn === -1) {
      for (let i = 0; i < sheetData.length; i++) {
        if (
          sheetData[i].cells[nameColumn] === undefined ||
          sheetData[i].cells[priceColumn] === undefined
        ) {
          continue
        }
        products.push([
          i,
          sheetData[i].cells[nameColumn],
          sheetData[i].cells[priceColumn],
          sheetData[i].cells[descriptionColumn]
            ? sheetData[i].cells[descriptionColumn]
            : "",
        ])
        productsPrices.push(textToPriceParser(sheetData[i].cells[priceColumn]))
      }
    } else {
      for (let i = 0; i < sheetData.length; i++) {
        if (
          sheetData[i].cells[nameColumn] === undefined ||
          sheetData[i].cells[priceColumn] === undefined ||
          sheetData[i].cells[referenceIdColumn] === undefined
        ) {
          continue
        }
        products.push([
          sheetData[i].cells[referenceIdColumn],
          sheetData[i].cells[nameColumn],
          sheetData[i].cells[priceColumn],
          sheetData[i].cells[descriptionColumn]
            ? sheetData[i].cells[descriptionColumn]
            : "",
        ])
        productsPrices.push(textToPriceParser(sheetData[i].cells[priceColumn]))
      }
    }

    // Reset the column name to 0
    productsPrices[0] = 0
    if (productsPrices.some((price) => !textToPriceParser(price))) {
      await MySwal.fire({
        title: "Error!",
        text: "Price must be in a number format like 1234 or 1234.56",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    const storesRef = doc(collection(db, "StoreOwners"), user.uid)
    const storeRef = doc(collection(storesRef, "allStores"))

    let store = {
      storeName: storeName,
      storeOwner: storeOwner ? storeOwner : storeCreator,
      storeCreator: storeCreator,
      description: storeDescription,
      currency: selectedCurrency.code,
      ownerID: user.uid,
      storeID: storeRef.id,
      createAt: serverTimestamp(),
      columnsList: JSON.stringify(products),
      link: `https://popsto.re/store/${user.uid}/${storeRef.id}`,
      columns: columns,
      locked,
      inTransfer: storeOwner ? true : false,
    }

    await setDoc(storeRef, store)

    logEvent(analytics, "store_created", {
      name: storeName,
      storeOwner: storeOwner ? storeOwner : storeCreator,
      locked,
      inTransfer: storeOwner ? true : false,
    })
    try {
      if (storeOwner) {
        sendTransferEmail(storeOwner, store, user.uid)
      }
    } catch (error) {
      console.log("Failed to send email", error)
    }

    await MySwal.fire({
      title: "Success!",
      text: "PopStore created successfully",
      icon: "success",
      confirmButtonText: "Ok",
    })
    localStorage.removeItem("columns")
    localStorage.removeItem("sheetData")
    navigate("/popstore/all")
  }

  const cancelStore = async (e) => {
    e.preventDefault()
    localStorage.removeItem("sheetData")
    localStorage.removeItem("columns")
    navigate("/")
  }

  const updateSelectedColumn = async (e, column, index, c) => {
    let cols = localStorage.getItem("columns")
    cols = JSON.parse(cols)
    cols[column] = index
    let tempValues = Object.values(cols)
    if (tempValues.includes(index)) {
      let key = Object.keys(cols).find(
        (key) => cols[key] === index && key !== column,
      )
      if (key !== undefined) {
        cols[key] = -1
      }
    }

    // Check price column for numeric values
    let productsPrices = []
    for (let i = 0; i < sheetData.length; i++) {
      if (
        (sheetData[i].cells[cols["Price"]] === undefined &&
          cols["Price"] !== -1) ||
        (sheetData[i].cells[cols["Name"]] === undefined && cols["Name"] !== -1)
      ) {
        continue
      }
      productsPrices.push(textToPriceParser(sheetData[i].cells[cols["Price"]]))
    }

    productsPrices[0] = 0
    if (productsPrices.some((price) => !textToPriceParser(price))) {
      //if(cols['Price'] !== -1 && productsPrices.includes(NaN)){
      await MySwal.fire({
        title: "Error!",
        text: "Price must be in a number format like 1234 or 1234.56",
        icon: "error",
        confirmButtonText: "Ok",
      })
      document.getElementById(`${c}-${index}`).textContent = "Select Column"
      return
    }
    // Same column information
    setCol(cols)
    if (document.getElementById(`${c}-${index}`)) {
      document.getElementById(`${c}-${index}`).textContent = column
    }
    localStorage.setItem("columns", JSON.stringify(cols))
  }

  return (
    <>
      <Typography gutterBottom variant="h4">
        Map your data
      </Typography>
      <form onSubmit={saveStore}>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={4} md={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Store Name"
              helperText=""
              variant="outlined"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Store Owner"
              disabled
              helperText=""
              type="email"
              variant="outlined"
              value={storeCreator}
              onChange={(e) => setStoreCreator(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              multiline
              rows={5}
              label="Store Description"
              helperText=""
              variant="outlined"
              value={storeDescription}
              onChange={(e) => setStoreDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Select
              fullWidth={true}
              label="Select Column"
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              id="currency"
            >
              {uniqueCurrencies().map((currency, i) => (
                <MenuItem key={`${i}`} value={currency}>
                  {currency.code} - {currency.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <LockStoreSwitch locked={locked} onChange={(v) => setLocked(v)} />
          </Grid>
          <Grid item xs={12} md={12}></Grid>
        </Grid>
      </form>

      <Box sx={{ backgroundColor: palette.grey[200] }}>
        <TableContainer>
          <Table style={{ tableLayout: "fixed" }}>
            <caption>
              Reference Id and Description are optional. Reference Id is
              generated automatically if not selected
            </caption>
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell key={index}>
                    <Select
                      sx={{ maxWidth: "200px" }}
                      fullWidth={true}
                      id={`${column}-${index}`}
                      label="Select Column"
                    >
                      {dbColumns.map((dbColumn, i) => (
                        <MenuItem
                          disabled={
                            col[dbColumn] !== -1 &&
                            dbColumn !== "Ignore" &&
                            col[dbColumn] !== index
                          }
                          onClick={(e) =>
                            updateSelectedColumn(e, dbColumn, index, column)
                          }
                          key={`${index}-${dbColumn}`}
                        >
                          {dbColumn}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sheetData?.map((row, rowIndex) => {
                return (
                  <TableRow key={`row-${rowIndex}`}>
                    {row.cells.map((cell, cellIndex) => (
                      <TableCell key={`cell-${rowIndex}-${cellIndex}`}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />
      <AddOwner setStoreOwner={(user) => setStoreOwner(user)} />
      <Divider sx={{ mb: 2 }} />
      <Stack direction="row" gap={2} alignItems="center" mb={2}>
        <Button variant="outlined" onClick={cancelStore}>
          Cancel
        </Button>
        <Button
          style={{ marginLeft: "1rem" }}
          color="primary"
          variant="contained"
          onClick={saveStore}
        >
          Create PopStore
        </Button>
      </Stack>
    </>
  )
}

export default NewPopstore
