import { Box, Typography } from "@mui/material"
import React from "react"
import NotFoundImage from "./notFound.jpg"

const NotFound = () => {
  return (
    <Box>
      <Typography gutterBottom variant="h4">
        404 | Page not found
      </Typography>
      <Typography gutterBottom variant="h6">
        This page has been removed or the link you clicked might be broken.
      </Typography>
      <Box sx={{ maxWidth: "50vh" }}>
        <img width={"90%"} alt="Not found" src={NotFoundImage} />
      </Box>
    </Box>
  )
}

export default NotFound
