import { Box, Typography } from "@mui/material"
import React from "react"
import { CustomListItem } from "./CustomListItem.tsx"
export const StoreItems = ({ items, currency }) => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="h5">Items</Typography>
    {items.map((i) => (
      <CustomListItem key={i[1]} k={i[1]} v={`${i[2]} ${currency}`} />
    ))}
  </Box>
)
