import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ThemeProvider } from "@mui/material/styles"
import { Toaster } from "react-hot-toast"
import "./App.css"
import LandingPage from "./pages/LandingPage/LandingPage"
import MyPopstore from "./pages/MyPopstore/MyPopstore"
import NewPopstore from "./pages/NewPopstore/NewPopstore"
import OrdersPage from "./pages/OrdersPage/OrdersPage"
import OrderPage from "./pages/OrderPage/OrderPage"
import QRPage from "./pages/QRPage/QRPage"
import CustomersPage from "./pages/CustomersPage/CustomersPage.tsx"
import PackagingPage from "./pages/PackingPage/PackingPage"
import PopStore from "./pages/PopStore/PopStore"
import NotFound from "./pages/NotFound/NotFound"

import theme from "./config/theme"
import EditPopstore from "./pages/EditPopstore/EditPopstore"
import AdminPage from "./pages/AdminPage/AdminPage"
import AcceptStoreTransfer from "./pages/AcceptStoreTransfer/AcceptStoreTransfer.tsx"
import Header from "./components/Header/Header.tsx"
import { Container } from "@mui/material"
import Footer from "./components/Footer/Footer.tsx"
import ScrollToTop from "./hooks/scrollToTop.ts"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <BrowserRouter>
        <ScrollToTop />
        <Container sx={{ minHeight: "100vh" }} maxWidth="lg">
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route exact path="/popstore/all" element={<MyPopstore />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/popstore/create" element={<NewPopstore />} />
            <Route
              path="/popstore/:storeId/transfer/:ownerId"
              element={<AcceptStoreTransfer />}
            />
            <Route path="/store/:ownerId/:storeId" element={<PopStore />} />
            <Route
              path="/order/:ownerId/:storeId/:orderId"
              element={<OrderPage />}
            />
            <Route path="/QR/:qrCode" element={<QRPage />} />
            <Route path="/popstore/edit/:storeId" element={<EditPopstore />} />
            <Route
              path="/store/:ownerId/:storeId/customers"
              element={<CustomersPage />}
            />
            <Route
              path="/store/:ownerId/:storeId/orders"
              element={<OrdersPage />}
            />
            <Route
              path="/popstore/packaging/:storeId"
              element={<PackagingPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  )
}
export default App
