export type CurrencyInfo = {
  code: string
  country: string
  symbol: string
  name: string
  locale: string
}
export const currencies: CurrencyInfo[] = [
  {
    code: "AUD",
    country: "Australia",
    symbol: "AU$",
    name: "Australian Dollar",
    locale: "en-AU",
  },
  {
    code: "BRL",
    country: "Brazil",
    symbol: "R$",
    name: "Brazilian Real",
    locale: "pt-BR",
  },
  {
    code: "CAD",
    country: "Canada",
    symbol: "CA$",
    name: "Canadian Dollar",
    locale: "en-CA",
  },
  {
    code: "CHF",
    country: "Switzerland",
    symbol: "CHF",
    name: "Swiss Franc",
    locale: "fr-CH",
  },
  {
    code: "CNY",
    country: "China",
    symbol: "¥",
    name: "Chinese Yuan Renminbi",
    locale: "zh-CN",
  },
  {
    code: "DKK",
    country: "Denmark",
    symbol: "kr",
    name: "Danish Krone",
    locale: "da-DK",
  },
  {
    code: "EUR",
    country: "Eurozone",
    symbol: "€",
    name: "Euro",
    locale: "en-IE",
  },
  {
    code: "GBP",
    country: "United Kingdom",
    symbol: "£",
    name: "British Pound",
    locale: "en-GB",
  },
  {
    code: "ILS",
    country: "Israel",
    symbol: "₪",
    name: "Israeli Shekel",
    locale: "he-IL",
  },
  {
    code: "ISK",
    country: "Iceland",
    symbol: "kr",
    name: "Icelandic Krona",
    locale: "is-IS",
  },
  {
    code: "INR",
    country: "India",
    symbol: "₹",
    name: "Indian Rupee",
    locale: "en-IN",
  },
  {
    code: "JPY",
    country: "Japan",
    symbol: "¥",
    name: "Japanese Yen",
    locale: "ja-JP",
  },
  {
    code: "KES",
    country: "Kenya",
    symbol: "KSh",
    name: "Kenyan Shilling",
    locale: "sw-KE",
  },
  {
    code: "NOK",
    country: "Norway",
    symbol: "kr",
    name: "Norwegian Krone",
    locale: "nb-NO",
  },
  {
    code: "NZD",
    country: "New Zealand",
    symbol: "NZ$",
    name: "New Zealand Dollar",
    locale: "en-NZ",
  },
  {
    code: "PKR",
    country: "Pakistan",
    symbol: "₨",
    name: "Pakistani Rupee",
    locale: "en-PK",
  },
  {
    code: "SEK",
    country: "Sweden",
    symbol: "kr",
    name: "Swedish Krona",
    locale: "sv-SE",
  },
  {
    code: "TRY",
    country: "Turkey",
    symbol: "₺",
    name: "Turkish Lira",
    locale: "tr-TR",
  },
  {
    code: "TWD",
    country: "Taiwan",
    symbol: "NT$",
    name: "Taiwan New Dollar",
    locale: "zh-TW",
  },
  {
    code: "UAH",
    country: "Ukraine",
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    locale: "uk-UA",
  },
  {
    code: "USD",
    country: "United States",
    symbol: "$",
    name: "United States Dollar",
    locale: "en-US",
  },
  // Euro Countries
  {
    code: "EUR",
    country: "Austria",
    symbol: "€",
    name: "Euro",
    locale: "de-AT",
  },
  {
    code: "EUR",
    country: "Belgium",
    symbol: "€",
    name: "Euro",
    locale: "nl-BE",
  },
  {
    code: "EUR",
    country: "Cyprus",
    symbol: "€",
    name: "Euro",
    locale: "el-CY",
  },
  {
    code: "EUR",
    country: "Estonia",
    symbol: "€",
    name: "Euro",
    locale: "et-EE",
  },
  {
    code: "EUR",
    country: "Finland",
    symbol: "€",
    name: "Euro",
    locale: "fi-FI",
  },
  {
    code: "EUR",
    country: "France",
    symbol: "€",
    name: "Euro",
    locale: "fr-FR",
  },
  {
    code: "EUR",
    country: "Germany",
    symbol: "€",
    name: "Euro",
    locale: "de-DE",
  },
  {
    code: "EUR",
    country: "Greece",
    symbol: "€",
    name: "Euro",
    locale: "el-GR",
  },
  {
    code: "EUR",
    country: "Ireland",
    symbol: "€",
    name: "Euro",
    locale: "ga-IE",
  },
  { code: "EUR", country: "Italy", symbol: "€", name: "Euro", locale: "it-IT" },
  {
    code: "EUR",
    country: "Latvia",
    symbol: "€",
    name: "Euro",
    locale: "lv-LV",
  },
  {
    code: "EUR",
    country: "Lithuania",
    symbol: "€",
    name: "Euro",
    locale: "lt-LT",
  },
  {
    code: "EUR",
    country: "Luxembourg",
    symbol: "€",
    name: "Euro",
    locale: "lb-LU",
  },
  { code: "EUR", country: "Malta", symbol: "€", name: "Euro", locale: "mt-MT" },
  {
    code: "EUR",
    country: "Netherlands",
    symbol: "€",
    name: "Euro",
    locale: "nl-NL",
  },
  {
    code: "EUR",
    country: "Portugal",
    symbol: "€",
    name: "Euro",
    locale: "pt-PT",
  },
  {
    code: "EUR",
    country: "Slovakia",
    symbol: "€",
    name: "Euro",
    locale: "sk-SK",
  },
  {
    code: "EUR",
    country: "Slovenia",
    symbol: "€",
    name: "Euro",
    locale: "sl-SI",
  },
  { code: "EUR", country: "Spain", symbol: "€", name: "Euro", locale: "es-ES" },
]

export const getCurrencyInfoByLocale = (locale: string): CurrencyInfo => {
  const matchingCurrency = currencies.find(
    (currency) => currency.locale === locale,
  )

  // If a matching currency is found, return it; otherwise, return the international Euro
  return (
    matchingCurrency || currencies.find((currency) => currency.code === "EUR")!
  )
}
export const getCurrencyInfoByCode = (code: string): CurrencyInfo => {
    const matchingCurrency = currencies.find(
      (currency) => currency.code === code,
    )
  
    // If a matching currency is found, return it; otherwise, return the international Euro
    return (
      matchingCurrency || currencies.find((currency) => currency.code === "EUR")!
    )
  }

export const uniqueCurrencies = () => {
  const uniqueCodes = new Set()

  return currencies.filter((currency) => {
    if (!uniqueCodes.has(currency.code)) {
      uniqueCodes.add(currency.code)
      return true
    } else {
      return false
    }
  })
}

export const currenciesShort = [
  "AUD",
  "BRL",
  "CAD",
  "CHF",
  "CNY",
  "DKK",
  "EUR",
  "GBP",
  "ILS",
  "ISK",
  "INR",
  "JPY",
  "KES",
  "NOK",
  "NZD",
  "PKR",
  "SEK",
  "TRY",
  "TWD",
  "UAH",
  "USD",
]
