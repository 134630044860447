import { httpsCallable } from "firebase/functions"
import { functions } from "./firebase"

export const getUserID = (email) => {
  const getUserIdCall = httpsCallable(
    functions,
    `getUser?email=${email}`,
  )
  return getUserIdCall()
}
