import { ArrowBack } from "@mui/icons-material"
import { Button } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <Button
      startIcon={<ArrowBack />}
      variant="text"
      onClick={() => {
        window.scrollTo(0, 0)
        navigate(-1)
      }}
    >
      Go back
    </Button>
  )
}

export default BackButton
