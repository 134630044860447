import React, { useEffect, useState } from "react"
import Loading from "../../components/Loading"
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import Button from "@mui/material/Button"
import {
  collection,
  db,
  getDoc,
  doc,
  serverTimestamp,
  setDoc,
  analytics,
} from "../../service/firebase"
import { every } from "lodash"
import { useNavigate, useParams } from "react-router-dom"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import isEmail from "validator/lib/isEmail"
import isMobilePhone from "validator/lib/isMobilePhone"
import sendMail from "../../service/email"
import textToPriceParser from "../../functions/textToPriceParser"
import { logEvent } from "firebase/analytics"
import { LockOutlined } from "@mui/icons-material"
import { getCurrencyInfoByCode } from "../NewPopstore/currencies.ts"
import useDisableNumberInputScroll from "../../hooks/useDisableNumberInputScroll.ts"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"
import { sendEmailConfirmationRequest } from "./sendEmailConfirmationRequest.js"

const PopStore = () => {
  const { user } = useFirebaseUser()
  const { palette } = useTheme()
  const [store, setStore] = useState()
  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState(user?.email || "")
  const [phone, setPhone] = useState("")
  const { ownerId, storeId } = useParams()
  const [order, setOrder] = useState([])
  const MySwal = withReactContent(Swal)
  const [usercurrency] = React.useState()
  const [storecurrency, setStoreCurrency] = React.useState()
  const [submitting, setSubmitting] = React.useState(false)
  const [storeLink] = React.useState("https://popsto.re") //process.env.REACT_APP_STORE_LINK

  useDisableNumberInputScroll()

  const [infoAlertOpen, setInfoAlertOpen] = useState(true)
  let total = 0
  const getData = async () => {
    // const res = await axios.get('https://geolocation-db.com/json/').then(res => {
    //   setIP(res.data.IPv4)
    //   setUserData(res.data)
    //   setUserCountry(res.data.country_name)
    //   convertCurrency(res.data.country_name)
    // })
  }
  const runconvertCurrency = async () => {
    // if(usercurrency){
    //   const res = await axios.get('https://api.currencyapi.com/v3/latest?apikey='+process.env.REACT_APP_CURRENCY_API_KEY+'&value=1&base_currency='+storecurrency+'&currencies='+usercurrency).then(res => {
    //     setConvertedPrice(res.data.data[usercurrency].value)
    //   });
    // }
  }
  React.useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])
  React.useEffect(() => {
    //passing getData method to the lifecycle method
    runconvertCurrency()
  }, [storecurrency])
  React.useEffect(() => {
    //passing getData method to the lifecycle method
    runconvertCurrency()
  }, [usercurrency])
  useEffect(() => {
    ;(async () => {
      const storesRef = await collection(
        db,
        `/StoreOwners/${ownerId}/allStores`,
      )
      const store = await getDoc(doc(storesRef, storeId))
      if (store.exists()) {
        let data = store.data()
        data.columnsList = JSON.parse(data.columnsList)
        setStore(data)
        setStoreCurrency(data.currency)
        setLoading(false)
      }
    })()
  }, [ownerId, storeId])

  useEffect(() => {
    if (user) {
      setEmail(user.email)
    }
  }, [user])

  const saveOrder = async () => {
    setSubmitting(true)
    if (!isEmail(email) || email.trim() === "") {
      await MySwal.fire({
        title: "Error",
        text: "Please enter your email",
        icon: "error",
        confirmButtonText: "Ok",
      })
      setSubmitting(false)
      return
    }

    if (!isMobilePhone(phone) || phone.trim() === "") {
      await MySwal.fire({
        title: "Error",
        text: "Please enter your phone number",
        icon: "error",
        confirmButtonText: "Ok",
      })
      setSubmitting(false)
      return
    }

    if (order.length === 0 || every(order, (o) => o?.quantity < 1)) {
      await MySwal.fire({
        title: "Error",
        text: "Please add some items to your order",
        icon: "error",
        confirmButtonText: "Ok",
      })
      setSubmitting(false)
      return
    }
    // Check if a customer with this email already exists
    const customersRef = await collection(
      db,
      `/StoreOwners/${ownerId}/allStores/${storeId}/customers`,
    )
    const customer = await getDoc(doc(customersRef, email))
    if (!customer.exists()) {
      // Create a new customer
      const newCustomer = {
        uid: null,
        email: email.toLowerCase(),
        phone: phone,
        name: "",
        createdAt: serverTimestamp(),
      }
      let newCustomerRef = await doc(customersRef, email)
      await setDoc(newCustomerRef, newCustomer)
    }

    const Order = {
      uid: null,
      email: email.toLowerCase(),
      phone: phone,
      name: "",
      order: JSON.stringify(order.filter((o) => o?.quantity > 0)),
      storeId: storeId,
      createdAt: serverTimestamp(),
    }

    const ordersRef = await collection(
      db,
      `/StoreOwners/${ownerId}/allStores/${storeId}/Orders`,
    )
    const orderRef = await doc(ordersRef)
    await setDoc(orderRef, Order)

    logEvent(analytics, "order_placed", {
      name: store.storeName,
    })

    if (user) {
      let orderConfirmationEmail = `
    <!doctype html>
    <html lang="en">
    <head>
    <style>
       body{
            font-family: 'Arial', Helvetica, Arial, Lucida, sans-serif;
       }
    </style>
    <title>PopStore Order</title>
    </head>
    <body>
    <h1>Order Confirmation</h1>
    <p>Thank you for your order. Your order from <b>${store.storeName}</b> has been placed successfully. You can view your order by visiting the following link:</p>
    <p><a href="${storeLink}/order/${ownerId}/${storeId}/${orderRef.id}">View Order</a></p>
    <p>&nbsp;</p>
    <p>Regards</p>
    <p>PopStore Team</p>
    </body>
    </html>
    `
      try {
        sendMail(
          email,
          "PopStore Order Confirmation",
          orderConfirmationEmail,
        ).then(() => {
          console.log("Email sent")
          MySwal.fire({
            title: "Success",
            text: "Your order has been placed",
            icon: "success",
            confirmButtonText: "Ok",
          })
        })
      } catch (error) {
        MySwal.fire({
          title: "Error sending email",
          text: "Something went wrong when sending the confirmation email",
          icon: "error",
          confirmButtonText: "Ok",
        })
      }
    } else {
      await sendEmailConfirmationRequest({
        email: email,
        storeid: storeId,
        orderid: orderRef.id,
        ownerid: ownerId,
      })
        .then(() =>
          MySwal.fire({
            title: "Confirm order",
            text: "Your order has been placed. Please check your email to confirm your order.",
            icon: "info",
            confirmButtonText: "Ok",
          }),
        )
        .catch((e) => {
          MySwal.fire({
            title: "Error placing order",
            text: "Something when wrong when placing your order, please try again.",
            icon: "error",
            confirmButtonText: "Ok",
          })
        })
    }

    let newOrderEmail = `
            <!doctype html>
            <html lang="en">
            <head>
            <style>
               body{
                    font-family: 'Arial', Helvetica, Arial, Lucida, sans-serif;
               }
            </style>
            <title>New PopStore Order</title>
            </head>
            <body>
            <h1>Order</h1>
            <p>A new order has been placed on <b>${
              store.storeName
            }</b>. You can view your order by visiting the following link:</p>
            <p>The order is ${user ? "confirmed" : "not confirmed"}.</p>
            <p><a href="${storeLink}/order/${ownerId}/${storeId}/${
      orderRef.id
    }">View Order</a></p>
            <p>&nbsp;</p>
            <p>Regards</p>
            <p>PopStore Team</p>
            </body>
            </html>
            `
    if (isEmail(store.storeOwner)) {
      sendMail(store.storeOwner, "New PopStore Order", newOrderEmail)
    }
    setOrder([])
    setEmail("")
    setPhone("")
  }

  if (loading) return <Loading />
  return (
    <>
      <Collapse in={infoAlertOpen}>
        <Alert
          onClose={() => setInfoAlertOpen(false)}
          sx={{ my: 2 }}
          severity="info"
        >
          <AlertTitle>This is a PopStore!</AlertTitle>A super easy way to create
          and share a web order form in just 2 minutes! To find out more click{" "}
          <b>
            <Link
              component="a"
              href="https://popstore.blog/create-a-popstore/"
              target="_blank"
            >
              here
            </Link>
          </b>
          .
        </Alert>

        <Divider sx={{ my: 3 }} />
      </Collapse>

      <Typography gutterBottom variant="h4">
        {store.storeName}
      </Typography>

      <Typography
        gutterBottom
        variant="body1"
        sx={{ whiteSpace: "pre-line", mb: 2 }}
      >
        {store.description}
      </Typography>

      <Box style={{ backgroundColor: palette.grey[200], padding: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={6}>
            <h4>Items</h4>
          </Grid>
          <Grid item xs={3} md={2}>
            <h4>Price</h4>
          </Grid>
          <Grid item xs={3} md={2}>
            <h4>Quantity</h4>
          </Grid>
          <Grid item xs={3} md={2}>
            <h4>Total</h4>
          </Grid>
        </Grid>
        <div>
          {store.columnsList?.map((column, index) => {
            return (
              <Grid
                container
                spacing={2}
                key={index}
                style={{ marginBottom: "1rem" }}
              >
                <Grid item xs={3} md={6}>
                  <p>{column[1]}</p>
                </Grid>
                <Grid item xs={3} md={2}>
                  <p>
                    {Number(textToPriceParser(column[2]))}{" "}
                    {getCurrencyInfoByCode(store.currency).symbol}
                  </p>
                </Grid>
                <Grid item xs={3} md={2}>
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    onWheel={(e) => e.target.blur()}
                    id="outlined-basic"
                    type="number"
                    variant="outlined"
                    disabled={store.locked}
                    onChange={(e) => {
                      let newOrder = [...order]
                      newOrder[index] = {
                        ...newOrder[index],
                        quantity:
                          e.target.value === null ||
                          e.target.value === "" ||
                          Number(e.target.value) < 0
                            ? 0
                            : parseInt(e.target.value),
                        id: index,
                      }
                      setOrder(newOrder)
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={2}>
                  <p>
                    {Number(
                      Number(textToPriceParser(column[2])) *
                        Number(
                          Number(order[index]?.quantity)
                            ? order[index]?.quantity
                            : 0,
                        ),
                    ).toFixed(2)}{" "}
                    {getCurrencyInfoByCode(store.currency).symbol}
                  </p>
                </Grid>
              </Grid>
            )
          })}
          <Grid container spacing={2}>
            <Grid item xs={6} md={8} textAlign="right">
              <p>&nbsp;</p>
            </Grid>
            <Grid item xs={3} md={2}>
              <h4>Grand Total</h4>
            </Grid>
            <Grid item xs={3} md={2}>
              <h4>
                {order?.forEach((item, index) => {
                  if (item) {
                    total +=
                      Number(item.quantity) *
                      Number(textToPriceParser(store?.columnsList[item.id][2]))
                  }
                })}
                {Number(total).toFixed(2)}{" "}
                {getCurrencyInfoByCode(store.currency).symbol}
              </h4>
            </Grid>
          </Grid>
        </div>
      </Box>
      {store.locked && (
        <Alert icon={<LockOutlined />} sx={{ my: 2 }} severity="warning">
          This store is locked by the owner and it is not possible to place an
          order right now.
        </Alert>
      )}
      {!store.locked && (
        <>
          <Stack
            gap={2}
            flexWrap={"wrap"}
            sx={{ mt: 2 }}
            justifyContent="space-between"
            direction="row"
          >
            <Stack flexWrap={"wrap"} gap={2} direction="row">
              <TextField
                disabled={store.locked || user?.email}
                id="outlined-basic"
                label="Your Email"
                helperText=""
                type="email"
                variant="outlined"
                value={user?.email || email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                disabled={store.locked}
                id="outlined-basic"
                label="Your Phone"
                helperText=""
                type="phone"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Stack>

            <Button
              // sx={{ mt: 2 }}
              color="primary"
              variant="contained"
              disabled={submitting || store.locked}
              onClick={saveOrder}
            >
              Order
            </Button>
          </Stack>
          {!user && (
            <Alert icon={<LockOutlined />} sx={{ my: 2 }} severity="info">
              When you are not logged in you must confirm your e-mail to place
              an order. To confirm your e-mail, please check your inbox and
              click on the confirmation link after placing your order.
            </Alert>
          )}
        </>
      )}
    </>
  )
}

export default PopStore
