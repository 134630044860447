import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import React from "react"
import hippoImage from "../../images/hippo-white.png"
import lisboaImage from "../../images/lisboa.png"
import uiaImage from "../../images/UIA.png"
import almiImage from "../../images/almi.webp"
import voxpopImage from "../../images/VOXPOP.png"
import euImage from "../../images/EU.png"

const Footer = () => {
  const { palette } = useTheme()
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          py: 3,
          mt: 5,
          color: "white",
          backgroundColor: palette.grey[500],
          boxShadow: `0 0 0 100vmax ${palette.grey[500]}`,
          clipPath: " inset(0 -100vmax)",
        }}
      >
        <Stack
          flexWrap="wrap"
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <img width="100px" src={uiaImage} alt="hippo white" />
          <img width="100px" src={lisboaImage} alt="hippo white" />
          <img width="100px" src={voxpopImage} alt="hippo white" />
          <img width="100px" src={almiImage} alt="Almi" />

          <img width="100px" src={euImage} alt="hippo white" />
        </Stack>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          py: 3,
          margin: "auto",
          maxWidth: "800px",
          color: "white",
          backgroundColor: palette.grey[600],
          boxShadow: `0 0 0 100vmax ${palette.grey[600]}`,
          clipPath: " inset(0 -100vmax)",
        }}
      >
        <Typography gutterBottom variant="caption">
          PROJECT CO-FINANCED BY THE MUNICIPALITY OF LISBON AND THE EUROPEAN
          REGIONAL DEVELOPMENT FUND THROUGH THE URBAN INNOVATIVE ACTIONS (UIA) –
          UIA GUIDANCE VERSION 5 INITIATIVE
        </Typography>
      </Box>
      <Box
        sx={{
          py: 3,
          color: "white",
          backgroundColor: palette.grey[700],
          boxShadow: `0 0 0 100vmax ${palette.grey[700]}`,
          clipPath: " inset(0 -100vmax)",
        }}
      >
        <Stack
          flexWrap="wrap"
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <img src={hippoImage} alt="hippo white" />
          <Link
            component="a"
            target="_blank"
            href="https://popstore.blog/terms-and-conditions/"
            sx={{ color: "white", px: 2 }}
          >
            Terms & Conditions
          </Link>
          <Link
            component="a"
            target="_blank"
            href="https://popstore.blog/contact/"
            sx={{ color: "white", px: 2 }}
          >
            Contact us
          </Link>
          <Link
            component="a"
            target="_blank"
            href="https://popstore.blog/privacy-policy/"
            sx={{ color: "white", px: 2 }}
          >
            Privacy policy
          </Link>
        </Stack>
      </Box>
    </Container>
  )
}

export default Footer
