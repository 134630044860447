import { useState } from "react"
import {
  db,
  doc,
  collection,
  setDoc,
  serverTimestamp,
} from "../../service/firebase"
import { FieldValue, deleteDoc } from "firebase/firestore"

export interface ICreateStore {
  storeName: string
  storeOwner: string
  storeCreator: string
  description: string
  currency: string
  ownerID: string
  columns: {
    Price: number
    Ignore: number
    Name: number
  }
  columnsList: string
  locked: boolean
  inTransfer: boolean
  createAt: FieldValue
}

export const useTransferStore = (userId, oldStoreOwnerId, oldStoreId) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string | undefined>()

  const hasError = !!errorText

  const transfer = async (store: ICreateStore) => {
    if (userId && store) {
      setIsLoading(true)
      const storesRef = doc(collection(db, "StoreOwners"), userId)
      const storeRef = doc(collection(storesRef, "allStores"))
      setDoc(storeRef, {
        ...store,
        link: `https://popsto.re/store/${userId}/${storeRef.id}`,
        storeID: storeRef.id,
        createAt: serverTimestamp(),
        inTransfer: false,
        ownerID: userId,
      })
        .then((r) => {
          console.log("create database response")
          const collectionRef = collection(
            db,
            `/StoreOwners/${oldStoreOwnerId}/allStores`,
          )
          const storeRef = doc(collectionRef, oldStoreId)
          deleteDoc(storeRef)
            .then((r) => {
              console.log("successfully removed store", oldStoreId)
              setIsLoading(false)
            })
            .catch((e) => {
              console.log("error deleting", e)
              setErrorText(e)
            })
        })
        .catch((e) => setErrorText(e))
    }
  }

  return { transfer, isLoading, hasError, errorText }
}
