import { Box, Divider, Stack, Typography } from "@mui/material"
import React from "react"
import textToPriceParser from "../../functions/textToPriceParser"
import { IStore } from "../../types/IStore"
import { IOrder } from "../../types/IOrder"

interface IOrderListCompactProps {
  store: IStore
  order: IOrder
}

const OrderListCompact: React.FC<IOrderListCompactProps> = ({
  order,
  store,
}) => {
  return (
    <>
      <Stack gap={3} sx={{ mt: 3 }}>
        {order?.order?.map((product, index) => (
          <React.Fragment key={product.id}>
            <Stack>
              <Typography variant="body2">
                {store?.columnsList[product.id][1]}
              </Typography>
              <Typography variant="body2">
                Price:{" "}
                {Number(textToPriceParser(store?.columnsList[product.id][2]))}
                {store?.currency}
              </Typography>
              <Typography variant="body2">
                Quantity: {product.quantity}
              </Typography>

              <Typography variant="body2">
                Total:{" "}
                {Number(textToPriceParser(store?.columnsList[product.id][2])) *
                  product.quantity}{" "}
                {store?.currency}
              </Typography>
            </Stack>
            <Divider />
          </React.Fragment>
        ))}
        <Stack alignItems="flex-end">
          <Box sx={{ mt: 3, mr: 2 }}>
            <Typography variant="body2" fontWeight={"bold"}>
              Grand total:{"  "}
              {order?.order
                ?.reduce((prev, next) => {
                  return (
                    prev +
                    textToPriceParser(
                      store?.columnsList[next.id][2].replace(/,/, ""),
                    ) *
                      parseFloat(next.quantity)
                  )
                }, 0)
                ?.toFixed(2)}{" "}
              {store?.currency}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </>
  )
}

export default OrderListCompact
