import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Alert, Button, List, Typography } from "@mui/material"
import { useFirebaseUser } from "../../hooks/useFirebaseUser.ts"
import { useGetStore } from "../../hooks/firestore/useGetStore.ts"
import { CustomListItem } from "./components/CustomListItem.tsx"
import { StoreItems } from "./components/StoreItems.tsx"
import {
  ICreateStore,
  useTransferStore,
} from "../../hooks/firestore/useTransferStore.ts"

const AcceptStoreTransfer = () => {
  const { storeId, ownerId } = useParams()
  const navigate = useNavigate()
  const user = useFirebaseUser()
  const { store, isLoading, hasError, errorText } = useGetStore(
    ownerId,
    storeId,
  )
  const { transfer, isLoading: isTransfering } = useTransferStore(
    user.user?.uid,
    ownerId,
    store?.storeID,
  )

  const onTransferStoreClick = () => {
    transfer(store as ICreateStore)
      .then((r) => {
        navigate(`/popstore/all`)
      })
      .catch((e) => console.log("error transfering store"))
  }
  if (hasError) return <Typography variant="h5">{errorText}</Typography>
  if (isLoading) return null
  return (
    <>
      <Alert severity="info">
        This store is created by {store?.storeCreator}.
      </Alert>

      <List>
        <CustomListItem k={"Name:"} v={store?.storeName} />
        <CustomListItem k={"Description:"} v={store?.description} />
        <CustomListItem k={"Creator:"} v={store?.storeCreator} />
        <CustomListItem k={"Description:"} v={store?.description} />
        <CustomListItem k={"Currency:"} v={store?.currency} />
      </List>
      <StoreItems
        items={store?.columnsList ? JSON.parse(store.columnsList) : []}
        currency={store?.currency}
      />
      {user.user?.email === store?.storeOwner && (
        <Button
          disabled={isTransfering}
          sx={{ mt: 2 }}
          onClick={onTransferStoreClick}
          variant="outlined"
        >
          Accept taking over as owner for this store
        </Button>
      )}
    </>
  )
}

export default AcceptStoreTransfer
