import { useEffect, useState } from "react"

import { db, collection, getDocs } from "../../service/firebase"
import { ICustomer } from "../../types/ICustomer"

export const useGetStoreCustomers = (ownerId, storeId) => {
  const [customers, setCustomers] = useState<ICustomer[] | null>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string | undefined>()

  const hasError = !!errorText

  useEffect(() => {
    if (storeId && ownerId) {
      setIsLoading(true)
      const customersRef = collection(
        db,
        `/StoreOwners/${ownerId}/allStores/${storeId}/customers`,
      )
      const temp = [] as ICustomer[]
      getDocs(customersRef).then((customers) => {
        customers.forEach((doc) => {
          const customer = doc.data()
          temp.push(customer as ICustomer)
        })
        if (temp.length > 0) {
          setCustomers(temp)
          setIsLoading(false)
        } else {
          setErrorText("No store orders found")
          setIsLoading(false)
        }
      })
    }
  }, [storeId, ownerId])
  return { customers, isLoading, hasError, errorText }
}
