import React from "react";
import { ListItem, Stack, Typography } from "@mui/material";

export const CustomListItem = ({ k, v }) => (
    <ListItem sx={{ px: 0 }}>
      <Stack gap={2} direction="row">
        <Typography sx={{ minWidth: "170px" }}>{k}</Typography>
        <Typography fontWeight="600">{v}</Typography>
      </Stack>
    </ListItem>
  )