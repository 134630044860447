import React from "react"
import { ICustomer } from "../../types/ICustomer"
import { Card, CardContent, Stack, Typography } from "@mui/material"
import CustomerDetailsCompact from "./CustomerDetailsCompact.tsx"

interface ICustomersListCompactProps {
  customers: ICustomer[]
}

const CustomersListCompact: React.FC<ICustomersListCompactProps> = ({
  customers,
}) => {
  return (
    <Stack gap={2}>
      {customers.map((customer) => (
        <Card key={customer.email} raised={false}>
          <CardContent>
            <Typography variant="h6">Customer</Typography>
            {customer.name && (
              <Typography variant="body1">Name: {customer.name}</Typography>
            )}
            <Typography variant="body1">E-mail: {customer.email}</Typography>
            <Typography variant="body1">Phone: {customer.phone}</Typography>
          </CardContent>
          <CustomerDetailsCompact customer={customer} />
        </Card>
      ))}
    </Stack>
  )
}

export default CustomersListCompact
