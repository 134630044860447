import React from "react"
import { IStore } from "../../types/IStore"
import { Box, Divider, Typography } from "@mui/material"
import StoreItem from "./StoreItem.tsx"
import useDeviceSize from "../../hooks/useDeviceSize.ts"
import NoContent from "./noContent.png"

interface IStoresListProps {
  stores: IStore[]
  header?: string
  showNoContent: boolean
}

const StoresList: React.FC<IStoresListProps> = ({
  stores,
  header,
  showNoContent = false,
}) => {
  const { smallScreensUp } = useDeviceSize()
  if (stores.length > 0)
    return (
      <Box mt={3}>
        {header && (
          <Typography
            sx={{
              textAlign: !smallScreensUp ? "center" : "start",
              mt: 5,
              ml: 0.4,
            }}
            gutterBottom
            variant="h5"
          >
            {header}
          </Typography>
        )}
        {stores.map((s, i) => (
          <Box key={s.storeID}>
            <StoreItem s={s} />
            {stores.length - 1 !== i && <Divider data-testid="divider" sx={{ my: 2 }} />}
          </Box>
        ))}
      </Box>
    )
  else
    return showNoContent ? (
      <Box>
        <Typography gutterBottom variant="h6">
          No stores found. When you create a store it will be available here.
        </Typography>
        <img src={NoContent} alt="no stores found" />
      </Box>
    ) : null
}

export default StoresList
