import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Box,
  Stack,
} from "@mui/material"
import React from "react"
import { IStore } from "../../types/IStore"
import { IOrder } from "../../types/IOrder"
import textToPriceParser from "../../functions/textToPriceParser"

interface IOrderListProps {
  store: IStore
  order: IOrder
}

const OrderList: React.FC<IOrderListProps> = ({ order, store }) => {
  return (
    <Box>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <MuiTableRow>
            <TableCell>Product</TableCell>
            <TableCell>Price</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Total</TableCell>
          </MuiTableRow>
        </TableHead>
        <TableBody>
          {order.order.map((product) => (
            <MuiTableRow key={product.id}>
              <TableCell>{store?.columnsList[product.id][1]}</TableCell>
              <TableCell>
                {Number(textToPriceParser(store?.columnsList[product.id][2]))}{" "}
                {store?.currency}
              </TableCell>

              <TableCell align="right">{product.quantity}</TableCell>

              <TableCell align="right">
                {Number(
                  textToPriceParser(
                    (store?.columnsList[product.id][2]).replace(/,/, ""),
                  ) * parseFloat(product?.quantity ? product?.quantity : 0),
                ).toFixed(2)}{" "}
                {store?.currency}
              </TableCell>
            </MuiTableRow>
          ))}
        </TableBody>
      </Table>
      <Stack alignItems="flex-end">
        <Box sx={{ mt: 3, mr: 2 }}>
          <Typography variant="body2" fontWeight="bold">
            Customer total:{" "}
            {order?.order
              ?.reduce((prev, next) => {
                return (
                  prev +
                  textToPriceParser(
                    store?.columnsList[next.id][2].replace(/,/, ""),
                  ) *
                    parseFloat(next.quantity)
                )
              }, 0)
              ?.toFixed(2)}{" "}
            {store?.currency}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

export default OrderList
