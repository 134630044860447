import React from "react"
import Typography from "@mui/material/Typography"
import { useParams } from "react-router-dom"
import { Alert, Skeleton } from "@mui/material"
import CustomersList from "./CustomersList.tsx"
import { useGetStoreCustomers } from "../../hooks/firestore/useGetStoreCustomers.ts"
import BackButton from "../../components/BackButton/BackButton.tsx"

const CustomersPage = () => {
  const { storeId, ownerId } = useParams()
  const { customers, isLoading } = useGetStoreCustomers(ownerId, storeId)

  return (
    <>
      <BackButton />
      <Alert severity="info">
        This page shows all customers who have placed orders in this store. Some
        customers may have unconfirmed orders that is not included here.
      </Alert>
      <Typography gutterBottom variant="h4">
        Customers
      </Typography>
      {isLoading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <>
          {customers && customers.length > 0 ? (
            <CustomersList customers={customers} />
          ) : (
            <Typography gutterBottom variant="h6">
              No orders yet
            </Typography>
          )}
        </>
      )}
    </>
  )
}

export default CustomersPage
