import { createTheme } from "@mui/material/styles"
const theme = createTheme({
  palette: {
    primary: {
      light: "#6fa0a7",
      main: "#4C8991",
      dark: "#355f65",
      contrastText: "white",
    },
    secondary: {
      main: "#91544C",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { fontWeight: "800", letterSpacing: 1 },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: { textDecoration: "unset", ":hover": {
          textDecoration:'underline'
        } },
      },
    },
  },
  typography: {
    fontFamily: [
      "Quicksand",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
  },
})
export default theme
