import { IStore } from "../../../types/IStore"
import { IOrder } from "../../../types/IOrder"
import { compact } from "lodash"

export const mapOrderItemsToStoreColumns = (
  store: IStore,
  orders: IOrder[],
) => {
  const columns = JSON.parse(store.columnsList) as string[][]

  const ordersWithName = orders.map((o) => {
    return {
      ...o,
      order:
        o?.order?.length > 0
          ? compact(
              o.order.map((o) => {
                if (o !== null)
                  return {
                    ...o,
                    name: columns[o?.id][1],
                    price: parseInt(columns[o?.id][2]),
                  }
              }),
            )
          : [],
    }
  })
  return ordersWithName as IOrder[]
}
