/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { IStore } from "../../types/IStore"
import { db, collection, query, orderBy } from "../../service/firebase"
import { onSnapshot } from "firebase/firestore"

export const useGetStores = (ownerId: string | undefined) => {
  const [stores, setStores] = useState<IStore[] | null>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string | undefined>()

  let unsubscribe
  const hasError = !!errorText

  const getStores = () => {
    const q = query(
      collection(db, `StoreOwners/${ownerId}/allStores`),
      orderBy("createAt", "desc"),
    )
    unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const temp = [] as IStore[]
        setIsLoading(true)
        snapshot.forEach((doc) => {
          temp.push(doc.data() as IStore)
        })
        setStores(temp)
        setIsLoading(false)
      },
      (error) => {
        console.error("Get stores snapshot error", error)
        setErrorText("Error getting stores")
        setIsLoading(false)
      },
    )
  }
  useEffect(() => {
    if (ownerId) {
      getStores()
    }
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [ownerId, unsubscribe])

  return { stores, isLoading, hasError, errorText }
}
