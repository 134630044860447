import React from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
  Box,
  Collapse,
  Stack,
} from "@mui/material"
import { getTotalPrice } from "./functions/getTotalPrice.ts"
import { IStore } from "../../types/IStore.ts"

interface ICustomerDetailsProps {
  open: boolean
  store: IStore | null | undefined
  items: any
}

const CustomerDetails: React.FC<ICustomerDetailsProps> = ({
  open,
  store,
  items,
}) => (
  <MuiTableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ mx: 1, my: 4 }}>
          {store?.comment && (
            <Typography variant="body1" gutterBottom>
              {store.comment}
            </Typography>
          )}
          <Table size="small" aria-label="purchases">
            <TableHead>
              <MuiTableRow>
                <TableCell>Product</TableCell>
                <TableCell>Price</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Total</TableCell>
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {items.map((i) => (
                <MuiTableRow key={i.id}>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>
                    {i.price} {store?.currency}
                  </TableCell>
                  <TableCell align="right">{i.quantity}</TableCell>
                  <TableCell align="right">
                    {i.price * i.quantity} {store?.currency}
                  </TableCell>
                </MuiTableRow>
              ))}
            </TableBody>
          </Table>
          <Stack alignItems="flex-end">
            <Box sx={{ mt: 3, mr: 2 }}>
              <Typography variant="body2">
                Customer total: {getTotalPrice(items)} {store?.currency}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Collapse>
    </TableCell>
  </MuiTableRow>
)

export default CustomerDetails
