import { useEffect, useState } from "react"
import { db, collection, query, where, getDocs } from "../../service/firebase"
import { IOrder } from "../../types/IOrder"

export const useGetStoreOrders = (ownerId, storeId, customerEmail) => {
  const [storeOrders, setStoreOrders] = useState<IOrder[] | null>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [errorText, setErrorText] = useState<string | undefined>()
  const hasError = !!errorText

  useEffect(() => {
    if (customerEmail) {
      setIsLoading(true)
      const ordersRef = collection(
        db,
        `/StoreOwners/${ownerId}/allStores/${storeId}/Orders`,
      )
      const q = query(ordersRef, where("email", "==", customerEmail))
      getDocs(q).then((r) => {
        let temp = [] as IOrder[]
        r.forEach((doc) => {
          let d = doc.data()
          if (d.confirmed === false) return
          d.order = JSON.parse(d.order)
          temp.push(d as IOrder)
        })

        if (temp.length > 0) {
          setStoreOrders(temp)
          setIsLoading(false)
        } else {
          setErrorText("No store orders found")
          setIsLoading(false)
        }
      })
    }
  }, [storeId, ownerId, customerEmail])
  return { storeOrders, isLoading, hasError, errorText }
}
