import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router-dom"
import firebase, {
  db,
  doc,
  updateDoc,
  collection,
  getDoc,
  analytics,
} from "../../service/firebase"
import {
  Alert,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import styles from "../../components/DataTable/Sheets.module.css"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import Loading from "../../components/Loading"
import isEmail from "validator/lib/isEmail"
import textToPriceParser from "../../functions/textToPriceParser"
import LockStoreSwitch from "../../components/LockStoreSwitch/LockStoreSwitch.tsx"
import { logEvent } from "firebase/analytics"

const EditPopstore = () => {
  const navigate = useNavigate()
  const { storeId } = useParams()
  const [user, setUser] = useState()
  const [store, setStore] = useState()
  const [dbColumns] = useState(["Reference ID", "Name", "Price"])
  const [loading, setLoading] = useState(true)

  const MySwal = withReactContent(Swal)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user)
        const storesRef = collection(
          db,
          `/StoreOwners/${user.uid}/allStores`
        )
        const store = await getDoc(doc(storesRef, storeId))
        if (store.exists()) {
          let data = store.data()
          data.columnsList = JSON.parse(data.columnsList)
          setStore(data)
          setLoading(false)
        }
      } else {
        navigate("/")
      }

      localStorage.setItem(
        "columns",
        JSON.stringify({
          Name: -1,
          "Reference ID": -1,
          Price: -1,
          Ignore: 9,
        }),
      )
    })
  }, [navigate, storeId])

  const updateStore = async (e) => {
    e.preventDefault()

    if (store.storeName.trim() === "") {
      await MySwal.fire({
        title: "Error!",
        text: "Please select a name for PopStore",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    if (!isEmail(store.storeOwner) || store.storeOwner.trim() === "") {
      await MySwal.fire({
        title: "Error!",
        text: "Please add an email for PopStore owner",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    if (store.description.trim() === "") {
      await MySwal.fire({
        title: "Error!",
        text: "Please add description for PopStore",
        icon: "error",
        confirmButtonText: "Ok",
      })
      return
    }

    let updatedStore = {
      storeName: store.storeName,
      storeOwner: store.storeOwner,
      description: store.description,
      locked: store.locked,
    }

    const storesRef = await collection(db, `/StoreOwners/${user.uid}/allStores`)
    const storeRef = await doc(storesRef, storeId)

    await updateDoc(storeRef, updatedStore)
    if (store.locked) {
      logEvent(analytics, "store_locked", {
        name: store.storeName,
      })
    }
    await MySwal.fire({
      title: "Success!",
      text: "PopStore updated successfully",
      icon: "success",
      confirmButtonText: "Ok",
    })
    localStorage.removeItem("columns")
    navigate("/popstore/all")
  }

  const cancelStore = async (e) => {
    e.preventDefault()
    localStorage.removeItem("columns")
    navigate("/popstore/all")
  }

  if (loading) return <Loading />
  return (
    <>
      <Typography gutterBottom variant="h4">
        Edit your PopStore
      </Typography>

      {store?.inTransfer && (
        <Alert severity="info" sx={{ my: 2 }}>
          <Typography>
            This store is created for {store.storeOwner}, waiting for him/her to
            accept ownership.
          </Typography>
        </Alert>
      )}
      <form onSubmit={updateStore}>
        <LockStoreSwitch
          locked={store.locked}
          onChange={(v) => setStore({ ...store, locked: v })}
        />
        <Stack flexWrap="wrap" gap={2} direction="row">
          <TextField
            sx={{ width: "350px" }}
            label="Name"
            helperText=""
            value={store.storeName}
            onChange={(e) => setStore({ ...store, storeName: e.target.value })}
          />{" "}
          <TextField
            sx={{ width: "350px" }}
            label="Owner"
            helperText=""
            type="email"
            value={store.storeOwner}
            disabled
            onChange={(e) => setStore({ ...store, storeOwner: e.target.value })}
          />
          {store.storeCreator !== store.storeOwner && (
            <TextField
              disabled
              sx={{ width: "350px" }}
              fullWidth
              id="outlined-basic"
              label="Creator"
              helperText=""
              type="email"
              value={store.storeCreator}
            />
          )}
        </Stack>
        <TextField
          fullWidth
          sx={{ maxWidth: "350px", mt: 2 }}
          multiline
          rows={5}
          id="outlined-basic"
          label="Description"
          helperText=""
          variant="outlined"
          value={store.description}
          onChange={(e) => setStore({ ...store, description: e.target.value })}
        />
      </form>
      <div className="create-table-wrapper">
        <TableContainer>
          <Table style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                {dbColumns?.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {store.columnsList?.map((row, rowIndex) => {
                return (
                  <TableRow key={`row-${rowIndex}`}>
                    {row.map((cell, cellIndex) =>
                      cellIndex !== 3 ? (
                        <TableCell
                          key={`cell-${rowIndex}-${cellIndex}`}
                          className={styles["cell"]}
                        >
                          {cellIndex === 2
                            ? textToPriceParser(cell) + " " + store?.currency
                            : cell}
                        </TableCell>
                      ) : (
                        ""
                      ),
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Stack sx={{ mt: 2 }} direction="row">
        <Button variant="outlined" onClick={cancelStore}>
          Cancel
        </Button>
        <Button
          style={{ marginLeft: "1rem" }}
          color="primary"
          variant="contained"
          onClick={updateStore}
        >
          Update PopStore
        </Button>
      </Stack>
    </>
  )
}

export default EditPopstore
