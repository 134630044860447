const filterStoresByCreatedDays = (stores, days) =>
  stores.filter(
    (s) =>
      new Date(s?.createAt?.seconds * 1000) >
      new Date(new Date().getTime() - days * 24 * 60 * 60 * 1000),
  )

export const filterStoresByLast24h = (stores) =>
  filterStoresByCreatedDays(stores, 1)
export const filterStoresByLast7days = (stores) =>
  filterStoresByCreatedDays(stores, 7)
export const filterStoresByLast30days = (stores) =>
  filterStoresByCreatedDays(stores, 30)
export const filterStoresByLastYear = (stores) =>
  filterStoresByCreatedDays(stores, 365)
